<template>
  <!-- 智慧警务 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧警务</div>
      <div class="title-p">
        “智慧警务”是综合运用现代科学技术，整合公安信息资源，统筹公安业务应用系统，促进公安建设和公安执法、管理与服务的科学发展。其要旨是汇聚人的智慧，赋予物以智能，使汇集智慧的人与具备智能的物互存互动、互补互促，以实现公安效益最优化。它标志着公安信息化正在走向数字化、网络化、智能化三者的高度融合———智慧化。
      </div>
    </div>
    <!-- 从这里开始改 -->
    <div class="function">
      <div class="function-title">平台架构</div>
      <img src="../../assets/image/fbiImg/组 264.png" alt="" width="100%" />
    </div>
    <!-- 阶段部分 -->
    <div class="state">
      <img
        src="../../assets/image/fbiImg/jieduan.png"
        alt=""
        style="width: 100vw"
      />
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">云监控</div>
          <div class="sceneContent">
            云监控系统主要针对现有视频监控难以覆盖的内部区域和室外公共区域，通过部署支持无线WIFI的萤石、乐橙等摄像机，实现视频监控的无缝接入，实现视频监控的全方位、无死角覆盖。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">指挥调度</div>
          <div class="sceneContent">
            对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">九小场所管理</div>
          <div class="sceneContent">
            系统汇集了各种基础要素，方便警员可快速查看本辖区的基础要素概况与详细情况，有效提升辖区重点区域防控。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">数据融合与共享</div>
          <div class="sceneContent">
            支持多种物联网设备的数据接入，如监控设备、消防设备、电梯设备、闸口设备等；也能直接从政府、企业的各类数据平台拉取数据，所聚合的数据，可以快速共享到其他平台。
          </div>
        </div>
      </div>
    </div>
    <!-- 功能场景 -->
    <div class="function">
      <div class="monitor">
        <div class="zhihui">
          <!-- 数据融合与共享 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      alarmList: [
        {
          url: require("../../assets/image/buildingImg/shebe.png"),
          name: "设备报警",
          value: "重要能耗设备的运行状态异常报警。",
        },
        {
          url: require("../../assets/image/buildingImg/huanjing.png"),
          name: "环境质量报警",
          value: "空气质量，温度，湿度等异常报警。",
        },
        {
          url: require("../../assets/image/buildingImg/dian.png"),
          name: "电源故障报警",
          value: "设备电源故障，ups断电报警。",
        },
        {
          url: require("../../assets/image/buildingImg/wiFi.png"),
          name: "网络故障报警",
          value: "设备通讯及网络故障等异常报警。",
        },
        {
          url: require("../../assets/image/buildingImg/gaojin.png"),
          name: "报警级别设定",
          value: "基于事件的报警，报警分组管理，报警优先级管理。",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    go(i) {
      if (i == 1) {
        this.$router.push("CloudMonitoring");
      }
      if (i == 2) {
        this.$router.push("dispatch");
      }
      if (i == 3) {
        this.$router.push("nineSmallPlaces");
      }
      if (i == 4) {
        this.$router.push("DataFusion");
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/fbiImg/bg.png") no-repeat;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .profiles-box {
    margin-top: 15px;
    .profiles-title {
      text-align: center;
      width: 100%;
      height: 80px;
      line-height: 80px;
      // background: #3651d8;
      .text {
        font-size: 28px;

        font-weight: bold;
        color: #333333;
      }
    }
    .profiles-content {
      margin-top: 10px;
      // position: relative;
      height: 700px;
      // display: flex;

      // background-color: pink;
      padding: 0 150px;
      .profiles-content_img {
        text-align: center;
        line-height: 500px;
        // position: absolute;
        height: 100%;
        // left: 10%;
        background-color: hotpink;
      }
      // background: #ffffff;
      // .item {
      //   width: 25%;
      //   text-align: center;
      //   .item-title {
      //     font-size: 28px;
      //     padding: 30px 0;
      //     font-weight: normal;
      //     color: #1864e3;
      //   }
      //   .item-content {
      //     font-size: 16px;
      //     font-weight: normal;
      //     color: #6b6b6b;
      //   }
      // }
    }
  }
  .state {
    margin-top: 40px;
  }

  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .box1 {
      width: 100%;
      height: 400px;
      display: flex;
      background-color: #fff;
      .left {
        width: 50%;
        padding: 5% 10%;
        box-sizing: border-box;
        .left-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .left-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        background: url("../../assets/image/buildingImg/img1.png") no-repeat;
      }
    }
    .box2 {
      width: 100%;
      height: 400px;
      display: flex;
      margin-top: 20px;
      background-color: #fff;
      .left {
        width: 50%;
        height: 100%;
        background: url("../../assets/image/buildingImg/img2.png") no-repeat;
      }
      .right {
        width: 50%;
        padding: 7%;
        box-sizing: border-box;
        .right-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .right-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
    }
    .function-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .function-item {
        width: 18.5%;
        height: 260px;
        // margin-right: 2%;
        background-color: #fff;
        img {
          width: 100px;
          height: 100px;
          margin: 30px 0 0 0;
        }
        div {
          font-size: 20px;
          font-weight: 600;
          margin: 22px 0;
          color: #333333;
        }
        p {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
    .device {
      width: 100%;
      height: 400px;
      padding: 10%;
      box-sizing: border-box;
      background: url("../../assets/image/buildingImg/bottom.png") no-repeat;
      .device-text {
        font-size: 28px;
        font-weight: 300;
        color: #ffffff;
      }
    }

    .zhihui {
      .img {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        img {
          width: 49%;
        }
      }
      .four_num {
        padding: 0 5%;
        position: relative;
        margin-top: 20px;
        height: 400px;

        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
      }
      .son {
        padding: 30px 15px 10px 15px;
        width: 45%;
        height: 110px;
        background-color: #fff;
        h2 {
          color: #3651d8;
          margin-bottom: 20px;
        }
        .son_two {
          text-align: right;
        }
        span {
          color: #6b6b6b;
        }
      }
    }
  }
}
.scene {
  padding: 0 10%;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 180px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
      box-sizing: border-box;
      padding: 0 15px;
      > span {
        display: inline-block;
        width: 46%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(168, 168, 168, 0.5);
        border-radius: 0px;
        margin: 15px 2% 0 0;
        color: #fff;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
      }
    }
  }
}
.img2 {
  background: url("../../assets/image/fbiImg/组 312.png") no-repeat;
  background-size: 100% 100%;
}
.img3 {
  background: url("../../assets/image/fbiImg/组 311.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/fbiImg/组 310.png") no-repeat;
  background-size: 100% 100%;
}
.img5 {
  background: url("../../assets/image/fbiImg/组 309.png") no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/fbiImg/组 188.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/fbiImg/组 194.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/fbiImg/组 187.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/fbiImg/组 190.png") no-repeat;
  background-size: 100% 100%;
}
</style>
